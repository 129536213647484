"use strict";

(function () {
  var showSidebarBtns = document.querySelectorAll('.js-sidebar-show');
  var hideSidebarBtns = document.querySelectorAll('.js-sidebar-hide');
  var body = document.querySelector('body');
  var clickedEl = null;
  Array.prototype.forEach.call(showSidebarBtns, function (el, i) {
    el.addEventListener('click', function (event) {
      event.preventDefault();
      clickedEl = el;
      showSidebar();
    });
  });
  Array.prototype.forEach.call(hideSidebarBtns, function (el, i) {
    el.addEventListener('click', function (event) {
      event.preventDefault();
      hideSidebar();
    });
  });
  var sidebar = document.querySelector('.js-sidebar');
  var sidebarBg = document.querySelector('.js-sidebar-bg');

  function showSidebar() {
    sidebar.classList.add('active');
    sidebarBg.classList.add('active');
    clickedEl.classList.add('active');
    body.classList.add('ov-h');
  }

  function hideSidebar() {
    sidebar.classList.remove('active');
    sidebarBg.classList.remove('active');
    clickedEl.classList.remove('active');
    body.classList.remove('ov-h');
  } // menu


  var menuItems = document.querySelectorAll('.js-menu-item ins');
  var menuSubMenu = null;
  var menuItem = null;
  Array.prototype.forEach.call(menuItems, function (el, i) {
    el.addEventListener('click', function (event) {
      menuItem = event.target.parentNode.parentNode;
      menuSubMenu = menuItem.querySelector('.js-menu-next');
      menuItem.classList.toggle('active');
      slideToggle(menuSubMenu);
    });
  });
})();