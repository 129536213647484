"use strict";

(function () {
  if (document.querySelector('.js-collapse-title')) {
    var collapseTitles = document.querySelectorAll('.js-collapse-title');
    var collapseContent = null;
    Array.prototype.forEach.call(collapseTitles, function (collapseTitle) {
      collapseTitle.addEventListener('click', function (event) {
        var et = event.target;
        collapseContent = et.nextElementSibling;
        et.classList.toggle('active');
        slideToggle(collapseContent);
      });
    });
  }
})();